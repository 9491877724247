import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom"
import { useWindowSize } from "../../../hooks/useWindowSize";
import SideBar from "../sidebar"
import NewStoreModalWrap from "../new-store"

import Dropdown from 'react-bootstrap/Dropdown';

import Logo from "../../../assets/logo2.png"
import Noti from "../../../assets/noti-1.png"
import User1 from "../../../assets/user.png"
import DropIco from "../../../assets/drop-ico.png"
import Menu2 from "../../../assets/icons/menu.png"
import ShopGrIco from "../../../assets/icons/shop_gr.png"
import LocationIco from "../../../assets/icons/location.png"
import InfoErrorIco from "../../../assets/icons/info_error.svg"
import "./index.scss";


export const AppHeader = (props: any) => {
    const screenResolution: any = useWindowSize();
    const [isMobileMenuVisible, setShowVisible] = useState<boolean>(false)
    const [showNewStore, setShowNewStore] = useState<boolean>(false)
    let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
    loggedAccountInfo = JSON.parse(loggedAccountInfo);
    // console.log("screenResolution", screenResolution)


    const handleLoadStore = (storeId: any) => {
        let allStores: any[] = loggedAccountInfo?.myStores || [];
        let filteredStore = allStores.filter((eachStore: any) => eachStore.id === storeId)[0]
        loggedAccountInfo.selectedStore = filteredStore;
        localStorage.setItem("mamiVendorXAuth", JSON.stringify(loggedAccountInfo));

        window.location.reload();
    }
    return (
        <div className={props?.isWide ? "header-wrap space_in" : "header-wrap"}>
            <NewStoreModalWrap
                showNewStore={showNewStore}
                setShowNewStore={setShowNewStore}
            />

            {screenResolution?.width >= 1025 &&
                <div className="header-menus">
                    {/* <div className="each-menu">
                        <img src={Noti} alt="" />
                    </div>
                    <div className="each-menu">
                        <img src={ProfileIco} alt="" />
                    </div> */}
                    {props?.noStores &&
                        <div className="store_info">
                            <div className="store-name">
                                Review ongoing
                            </div>
                            {/* <div className="store-location">
                                
                            </div> */}
                        </div>
                    }
                    {!props?.noStores &&
                        <>
                            {(loggedAccountInfo?.selectedStore && !props?.showMainPageHeading && loggedAccountInfo?.selectedStore?.hasBankLinked === false) &&
                                <div className="nobank_linked">
                                    <img src={InfoErrorIco} alt="" />
                                    <div className="bank_txt">
                                        Please note that your store will not be visible to customers until you add a withdrawal method.
                                        <Link to={`/app/profile/withdrawal`}> Add now</Link>
                                    </div>
                                </div>
                            }
                            {(loggedAccountInfo?.selectedStore && !props?.showMainPageHeading && loggedAccountInfo?.selectedStore?.hasBankLinked === true) &&
                                <div className="store_info">
                                    <div className="store-name">
                                        <img src={ShopGrIco} />
                                        {loggedAccountInfo?.selectedStore?.name}
                                    </div>
                                    <div className="store-location">
                                        <img src={LocationIco} />
                                        {loggedAccountInfo?.selectedStore?.address?.city ? "," : ""} {loggedAccountInfo?.selectedStore?.address?.province || loggedAccountInfo?.selectedStore?.address?.country}
                                    </div>
                                </div>
                            }
                            {(loggedAccountInfo?.selectedStore && props?.showMainPageHeading && props?.mainPageHeading && loggedAccountInfo?.selectedStore?.hasBankLinked === true) &&
                                <div className="page-heading-content">
                                    {props?.mainPageHeading}
                                </div>
                            }
                        </>
                    }
                    <div className="other_items">
                        <div className="notify-cta">
                            <img src={Noti} alt="" />
                        </div>
                        <div className="profile-info">
                            <div className="profile-store-name">
                                <div className="name-ofuser">{loggedAccountInfo?.userProfile?.firstName} {loggedAccountInfo?.userProfile?.lastName}</div>
                                <div className="name-ofstore">{loggedAccountInfo?.selectedStore?.name}</div>
                            </div>
                            <div className="">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className="profile-img-drop">
                                        <img src={User1} alt="" className="user-img" />
                                        <div className="drop-ico">
                                            <img src={DropIco} />
                                        </div>
                                    </Dropdown.Toggle>
                                    {loggedAccountInfo?.myStores &&
                                        <Dropdown.Menu>
                                            <Dropdown.Item disabled={true} className="stores-heading">
                                                <div className="stores_list_heading_wrap">
                                                    <span>Stores</span>
                                                    {loggedAccountInfo?.myStores.length > 2 &&
                                                        <div className="see_all_stores">See all Stores</div>
                                                    }
                                                </div>

                                            </Dropdown.Item>
                                            {loggedAccountInfo?.myStores.map((eachStore: any, index: any) => {
                                                if (index <= 1) {
                                                    return (
                                                        <Dropdown.Item className="store-info_wrap" key={index} onClick={() => handleLoadStore(eachStore?.id)}>
                                                            <div className="store-brief">
                                                                <div className="store-logoico"><img src={eachStore?.logoUrl} alt="" /></div>
                                                                <div className="store-name-location">
                                                                    <div className="store-name-text">{eachStore?.name}</div>
                                                                    <div className="store-location-text">
                                                                        {eachStore?.address?.city && ","} {eachStore?.address?.province || eachStore?.address?.country}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Dropdown.Item>
                                                    )
                                                }
                                                return <></>
                                            })
                                            }
                                            <Dropdown.Item className="add-new-store" onClick={() => setShowNewStore(true)}> + Add a new store <span>coming soon</span></Dropdown.Item>

                                            {/* < Dropdown.Item className="store-brief">Store 2</> */}
                                        </Dropdown.Menu>
                                    }
                                </Dropdown>

                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                screenResolution?.width <= 1024 &&
                <div className="header-menus" >
                    <div className="mobile-logo">
                        <Link className="" to={`/app`}>
                            <img src={Logo} alt="" />
                        </Link>
                    </div>
                    <div className="left-heading">

                        <div className="each-menu small">
                            <img src={Noti} alt="" />
                        </div>
                        <div className="each-menu small" onClick={() => setShowVisible(true)}>
                            <img src={Menu2} alt="" />
                        </div>
                    </div>
                </div>
            }
            {
                isMobileMenuVisible &&
                <SideBar
                    setShowNewStore={setShowNewStore}
                    setShowVisible={setShowVisible}
                    isMobile={isMobileMenuVisible}
                />
            }
        </div >
    )
}
// const mapDispatchToProps = {app
// };

// const mapStateToProps = (state: any) => {
//     return {
//         // loginRequest: state.onboardingOutboundReducers.loginReducer,
//     };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HeadeWrap);