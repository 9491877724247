import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { useWindowSize } from "../../../hooks/useWindowSize";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal'
// import { DatePickerField } from "../../../shared/_helpers/datepicker";
import { NavLink, Link } from "react-router-dom"

import "react-datepicker/dist/react-datepicker.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { TableComponent } from "../../../shared/components/table"
import { InAppTemplate } from "../../../shared/templates/inapp";
import { storeActions } from "../../../redux/actions/in-app"
import SearchIco from "../../../assets/icons/search.svg"
import PP from "../../../assets/pp.svg"
import Caret1 from "../../../assets/icons/left-caret.svg"
import Caret2 from "../../../assets/icons/right-caret.svg"
import Chat from "../../../assets/chat.svg"
import CallNow from "../../../assets/call.svg"
import { LoadingItem } from "../../../shared/components/loading"
import { getDateFromISO } from "../../../shared/utils"
import "../index.scss"

const getStoreOrders = async ({ pageProps, payload }: any) => {

    await pageProps.getStoreOrders(payload);
}

const InAppContent = ({ pageProps }: any) => {
    const [startDate, onStartChange] = useState<any>();
    const [endDate, onEndChange] = useState<any>();
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);
    const [deliveryInfo, setDeliveryInfo] = useState<any>();
    const screenResolution: any = useWindowSize();
    const productsList = [
        {
            productName: "Strawberries",
            productImg: "https://loremflickr.com/30/30/food?random=34",
            instock: 300,
            unitPrice: 2032,
            category: "Fashion",
            isOn: true,
            salesAmount: 30,
            customerName: "Jerry Mark",
            customerEmail: "Jerry.Mark@gmail.com",
            customerPhone: "028737833",
            customerAddress: "Close 104, Sri County",
            dateCreated: "23rd Feb., 2021"
        },
        {
            productName: "Strawberries",
            productImg: "https://loremflickr.com/30/30/food?random=14",
            instock: 300,
            unitPrice: 2032,
            category: "Fashion",
            isOn: false,
            salesAmount: 30,
            customerName: "Pack Manny",
            customerEmail: "Pack.Manny@gmail.com",
            customerPhone: "8376892",
            customerAddress: "Road 23, Sri County",
            dateCreated: "23rd Feb., 2021"
        },
        {
            productName: "Strawberries",
            productImg: "https://loremflickr.com/30/30/food?random=10",
            instock: 300,
            unitPrice: 2032,
            category: "Fashion",
            isOn: true,
            salesAmount: null,
            customerName: "Matt Mzaga",
            customerEmail: "Matt.Mzaga@gmail.com",
            customerPhone: "028673938",
            customerAddress: "Lerry Lana, Wendy Court",
            dateCreated: "23rd Feb., 2021"
        },
        {
            productName: "Strawberries",
            productImg: "https://loremflickr.com/30/30/food?random=20",
            instock: 300,
            unitPrice: 2032,
            category: "Fashion",
            isOn: true,
            salesAmount: 0,
            customerName: "Karl Max",
            customerEmail: "Karl.Max@gmail.com",
            customerPhone: "028673938",
            customerAddress: "Rodri Way, Pakway Lane",
            dateCreated: "23rd Feb., 2021"
        },
        {
            productName: "Strawberries",
            productImg: "https://loremflickr.com/30/30/food?random=24",
            instock: 300,
            unitPrice: 2032,
            category: "Fashion",
            isOn: false,
            salesAmount: null,
            customerName: "Maddison Jane",
            customerEmail: "Maddison.Jane@gmail.com",
            customerPhone: "0136576893",
            customerAddress: "Laanny Way, Meffy Lane",
            dateCreated: "23rd Feb., 2021"
        }
    ]
    let page = 1,
        size = 200;

    useEffect(() => {
        let payload = `Page=${page}&Size=${size}`;
        getStoreOrders({
            pageProps,
            payload
        })


    }, [])
    const ReportTable = ({ reportData }: any) => {
        let orderList: any[] = reportData?.results;
        if (orderList.length >= 1) {
            orderList = orderList.filter(eachItem => eachItem.status !== "Successful")
            return (
                <>
                    <thead>
                        <tr>
                            <th>
                                <span>S/N</span>
                            </th>
                            <th>
                                <span>Order ID</span>
                            </th>
                            <th>Status</th>
                            <th> <span>Created At</span></th>
                            <th> <span>Action</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderList.map((eachItem: any, index: any) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>
                                            {eachItem.number}
                                        </td>
                                        <td>
                                            {eachItem.status === "Intent" && "Pending"}
                                            {eachItem.status === "Failed" && "Failed"}
                                            {eachItem.status === "Successful" && "Completed"}
                                        </td>
                                        <td>
                                            <div className="date-item">{getDateFromISO({ date: eachItem.createdDate }) || "N/A"}</div>
                                        </td>
                                        <td>
                                            <div className="view-order">
                                                <Link to={`/app/my-orders/${eachItem.id}`}>View Order</Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        {/* {
                        productsList.map((eachItem, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div className="product-info">
                                            <img src={eachItem.productImg} alt="" />
                                            <div className="prod-name">
                                                {eachItem.productName}
                                                <span>View Details</span>
                                            </div>
                                        </div>
                                    </td>

                                    <td><span className="unit-price">${eachItem.unitPrice}</span> </td>
                                    <td><span className="unit-price">{eachItem.instock}</span> </td>
                                    <td><span className="unit-price">$2.00</span> </td>
                                    <td><span className="unit-total">$31.19</span> </td>
                                    <td><span className="cate">{eachItem.category}</span> </td>
                                    <td><div className="date-item">{eachItem.dateCreated}</div> </td>
                                    <td><div className="date-item">Paid</div> </td>

                                    <td><div className="date-item">{eachItem.dateCreated}</div> </td>
                                    <td>
                                        <button onClick={() => { setShowDeliveryModal(true); setDeliveryInfo(eachItem) }}>Delivery Address</button>
                                    </td>
                                </tr>
                            )
                        })
                    } */}

                    </tbody>
                </>
            )
        } else {
            <div className="order-wrap">

                <div className="order-items-list">
                    <div className="all_products">
                        Order details unavailable
                    </div>
                </div>
            </div>
        }

        return <></>

    }

    const MobileDataGrid = ({ reportData }: any) => {

        let orderList: any[] = reportData?.results;
        if (orderList.length >= 1) {
            orderList = orderList.filter(eachItem => eachItem.status !== "Successful")
            return (
                <div className="mobile-data-grid">
                    {(orderList.length >= 1) &&
                        <>
                            {orderList.map((eachItem: any, index: any) => {
                                return (
                                    <div className="each_data_item" key={index}>
                                        <div className="top-tiny-txt">{getDateFromISO({ date: eachItem.createdDate }) || "N/A"} </div>
                                        <div className="main-txt-head">
                                            <div className="head-txt">
                                                <span>Order number</span>
                                                {eachItem?.number}
                                            </div>
                                            
                                            {eachItem.status === "Intent" &&
                                                <div className="other-head-txt ongoing">
                                                    Pending
                                                </div>
                                            }
                                            {eachItem.status === "Failed" &&
                                                <div className="other-head-txt failed">
                                                    Failed
                                                </div>
                                            }
                                        </div>
                                        {/* <div className="availability">
                                            <div className="title-txt">Availability </div>
                                            
                                        </div> */}
                                        {/* <div className="cat-items-list">
                                            <div className="title-txt">Category </div>
                                      
                                        </div> */}
                                        <div className="cta-btns">
                                            <Link className="cta-link-txt" to={`/app/my-orders/${eachItem.id}`}>View Order</Link>
                                        </div>
                                    </div>
                                )
                            })}

                        </>

                    }

                </div >
            )
        } else {
            <div className="order-wrap">

                <div className="order-items-list">
                    <div className="all_products">
                        Order details unavailable
                    </div>
                </div>
            </div>
        }
        return <></>
    }


    return (
        <div className="inapp-content">
            <Modal className="address_modal_wrap" show={showDeliveryModal} onHide={() => setShowDeliveryModal(false)}>
                <Modal.Header closeButton>
                    {/* <div className="reason_heading">Reason for return </div> */}
                </Modal.Header>
                <Modal.Body>
                    <div className="customer-info">
                        <div className="customer-avatar">
                            <img src={PP} alt="" />
                        </div>
                        <div className="customer-details">
                            <div className="customer-name">{deliveryInfo?.customerName}</div>
                            <div className="customer-email">{deliveryInfo?.customerEmail}</div>
                            <div className="inbox-cta">
                                <button type="button">
                                    <img src={Chat} alt="" />
                                    <span>Message Customer</span>
                                </button>
                            </div>
                            <div className="call-cta">
                                <button type="button">
                                    <img src={CallNow} alt="" /> <span>{deliveryInfo?.customerPhone}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="customer-address">
                        <h3>Delivery Address</h3>
                        {deliveryInfo?.customerAddress}
                    </div>

                </Modal.Body>
            </Modal>
            <div className="page-content lists-wrap with-heading">

                <div className="list-contanier">
                    <div className="list-title">All Orders</div>
                    <div className="list-content-wrap cards products-list">
                        {pageProps.getStoreOrdersRequest.request_status === "GET_VENDOR_ORDERS_PENDING" &&
                            <LoadingItem />
                        }
                        {pageProps.getStoreOrdersRequest.request_status === "GET_VENDOR_ORDERS_SUCCESS" &&
                            <div>
                                <div className="list-filters-wrap">
                                    <div className="each-filter for-search">
                                        <input type="text" placeholder="Enter Product title or category" />
                                        <button>
                                            <img src={SearchIco} alt="" />
                                        </button>
                                    </div>
                                    <div className="each-filter filter-options">
                                        <InputGroup>
                                            <Form.Control aria-label="Filter by" placeholder="Filter by" />

                                            <DropdownButton
                                                variant="outline-secondary"
                                                title=""
                                                align="end"
                                                drop="down"
                                            >
                                                <Dropdown.Item >New Orders</Dropdown.Item>
                                                <Dropdown.Item >Old Orders</Dropdown.Item>
                                                <Dropdown.Item >Min. Price</Dropdown.Item>
                                                <Dropdown.Item >Max Price</Dropdown.Item>
                                            </DropdownButton>
                                        </InputGroup>
                                    </div>
                                    <div className="each-filter for-search pt-0 pb-0 date-filter">
                                        <div className="date-range">
                                            <div className="each-date">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date: any) => {
                                                        onStartChange(date)
                                                    }}
                                                    // minDate={new Date("01-01-2021")}
                                                    maxDate={new Date()}
                                                    placeholderText="From date"
                                                />
                                            </div>
                                            <div className="each-date">
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={(date: any) => {
                                                        onStartChange(date)
                                                    }}
                                                    // minDate={new Date("01-01-2021")}
                                                    maxDate={new Date()}
                                                    placeholderText="To date"
                                                />
                                            </div>
                                        </div>
                                        <button className="date-cta">
                                            <img src={SearchIco} alt="" />
                                        </button>
                                    </div>
                                </div>
                                <div className="all-items">
                                    <div className="items-heading">Orders</div>
                                    <div className="sub-headings">
                                        <div className="each-menu">
                                            <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/my-orders">
                                                Ordered Products
                                            </NavLink>
                                        </div>
                                        <div className="each-menu">
                                            <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/my-orders/cancelled">
                                                Cancelled Orders
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="all-items-list">
                                        {screenResolution?.width >= 769 &&
                                            <TableComponent
                                                childComponent={<ReportTable reportData={pageProps.getStoreOrdersRequest.request_data.response} />}
                                            />
                                        }
                                        {(screenResolution?.width <= 768 && pageProps?.getStoreOrdersRequest?.request_data?.response) &&
                                            <MobileDataGrid
                                                reportData={pageProps.getStoreOrdersRequest.request_data.response}

                                            />
                                        }
                                        {/* <div className="pagination-wrap to-right">
                                            <div className="prev-page each-page">
                                                <img src={Caret1} alt="" />
                                            </div>
                                            <div className="prev-page each-page active">1</div>
                                            <div className=" each-page">2</div>
                                            <div className=" each-page">3</div>
                                            <div className=" each-page">4</div>
                                            <div className=" each-page">5</div>
                                            <div className="next-page each-page">
                                                <img src={Caret2} alt="" />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )

}


const ReturnedOrderList = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="My Orders" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}

const mapDispatchToProps = {
    getStoreOrders: storeActions.GetStoreOrders,
};

const mapStateToProps = (state: any) => ({
    // return {
    getStoreOrdersRequest: state.allProductReducers.getStoreOrderReducer,

    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(ReturnedOrderList);