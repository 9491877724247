import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom"
import { useWindowSize } from "../../../hooks/useWindowSize";
import { InAppTemplate } from "../../../shared/templates/inapp";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Switch from "react-switch";
import { WithContext as ReactTags } from 'react-tag-input';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone, { IDropzoneProps, ILayoutProps } from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import ImgIco from "../../../assets/image_icon.svg"
import EditIco from "../../../assets/edit.svg"
import AddImg from "../../../assets/add-img.svg"
import UploadIcon from "../../../assets/icons/upload.svg"
import DoneIcon from "../../../assets/icons/done.svg"
import RemoveIcon from "../../../assets/icons/remove.svg"
import RemoveIcon2 from "../../../assets/icons/remove2.svg"
import Clock from "../../../assets/icons/clock.svg"


import Shop from "../../../assets/icons/shop.png"
import { AlertMsg } from "../../../shared/components/alert-msg"
import { LoadingItem } from "../../../shared/components/loading"
import { storeActions } from "../../../redux/actions/in-app"
import { history } from "../../../shared/_helpers/history";

const getStoreDetailsInfo = async ({ pageProps, payload }: any) => {
    await pageProps.getStoreDetails(payload, true);
}

const updateStoreDetailsInfo = async ({ pageProps, payload, storeLogo }: any) => {

    await pageProps.updateStoreDetails(payload, storeLogo);
}

const storeSelectedStoreUrl = (urlParam: any) => {
    const index = urlParam.lastIndexOf('/');

    const slicedUrl = urlParam.slice(index + 1);

    return slicedUrl;
}

const InAppContent = ({ pageProps }: any) => {
    const [startDate, onStartChange] = useState<any>();
    const [endDate, onEndChange] = useState<any>();
    const [IsStoreFetched, setIsStoreFetched] = useState<boolean>(false);
    const [storeInfoFetched, setIsStoreInfoFetched] = useState<any>();
    const [isSameDayDelivery, setIsSameDayDelivery] = useState<boolean>(false);
    const [isInstantPayoutEnabled, setInstantPayoutEnabled] = useState<boolean>(false);
    const [selectedDayOfWeek, setSelectedDayOfWeek] = useState<any>();
    const [selectedHourFrom, setSelectedHourFrom] = useState<any>();
    const [selectedHourFromValue, setSelectedHourFromValue] = useState<any>("");
    const [selectedHourTo, setSelectedHourTo] = useState<any>();
    const [selectedHourToValue, setSelectedHourToValue] = useState<any>("");
    const [storeHoursList, setStoreHoursList] = useState<any[]>([]);
    const [storeHoursSaved, setStoreHoursSaved] = useState<any[]>([]);
    const [storeHoursFromList, setStoreHoursFromList] = useState<any[]>([]);
    const [storeHoursToList, setStoreHoursToList] = useState<any[]>([]);
    const [allStoreTags, setStoreTags] = useState<any>([]);
    const [storeTagsSuggestions, setStoreTagsSuggestions] = useState<any>([]);
    const [storeTagValues, setStoreTagValues] = useState<any>([]);

    const [storeLogo, setStoreLogo] = useState<any>();
    // const [storeLogo, setStoreLogo] = useState<any>({ image: Shop });
    const [isStoreLogoNew, setStoreLogoNew] = useState(false);
    let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
    const screenResolution: any = useWindowSize();
    loggedAccountInfo = JSON.parse(loggedAccountInfo).selectedStore;


    useEffect(() => {
        getStoreDetailsInfo({
            pageProps,
            payload: storeSelectedStoreUrl(loggedAccountInfo?.url)
        }).then(() => {

            setIsStoreFetched(true)

        })

        updateStoreDetailsInfo({
            pageProps,
            payload: "CLEAR"
        })



    }, [])

    useEffect(() => {
        if (IsStoreFetched && pageProps.getStoreDetailsRequest.request_status === "GET_STORE_DETAILS_SUCCESS") {
            let storeInfo = pageProps.getStoreDetailsRequest.request_data.response;
            const start: any = 0;
            const end: any = 23;
            const range: any[] = [...Array(end - start + 1).keys()].map(x => x + start);


            setIsStoreInfoFetched(storeInfo)
            setStoreHoursFromList(range)
            setIsSameDayDelivery(storeInfo?.hasSameDayDelivery)
            setInstantPayoutEnabled(storeInfo?.instantPayoutEnabled)
            if (storeInfo?.fetchedCats && storeInfo?.fetchedCats.length >= 1) {
                let allSuggestions = storeInfo?.fetchedCats.map((catItem: any, index: any) => {

                    return {
                        id: catItem?.name,
                        text: catItem?.name
                    };
                });

                setStoreTagsSuggestions(allSuggestions)

            }


            if (storeInfo?.tags && storeInfo?.tags.length >= 1) {
                let allTags = storeInfo?.tags.map((catItem: any, index: any) => {

                    return {
                        id: catItem,
                        text: catItem
                    };
                });

                setStoreTagValues(storeInfo?.tags)
                setStoreTags(allTags)

            }




            if (storeInfo?.availableTimes && storeInfo?.availableTimes.length >= 1) {
                let availableTimes = storeInfo?.availableTimes;
                let hoursList: any[] = [];
                let hoursListSaved: any[] = [];
                let availableHrs = availableTimes.map((eachEntry: any, index: any) => {

                    let hours = `${returnTime(eachEntry?.timeRanges[0]?.from)} - ${returnTime(eachEntry?.timeRanges[0]?.to)}`
                    let entry = { ...eachEntry, hours }



                    hoursList = hoursList.filter((entry: any) => entry.day !== eachEntry?.day);
                    hoursListSaved = hoursListSaved.filter((entry: any) => entry.day !== eachEntry?.day);
                    const map: any = {
                        'Monday': 1,
                        'Tuesday': 2,
                        'Wednesday': 3,
                        'Thursday': 4,
                        'Friday': 5,
                        'Saturday': 6,
                        'Sunday': 7
                    };


                    hoursList.push(entry)
                    hoursListSaved.push(eachEntry)
                    hoursList.sort((a: any, b: any) => {
                        return map[a.day] - map[b.day];
                    });
                    hoursListSaved.sort((a: any, b: any) => {
                        return map[a.day] - map[b.day];
                    });


                    // return {
                    //     hoursList,
                    //     hoursListSaved
                    // }
                })


                
                

                setStoreHoursList(hoursList)
                setStoreHoursSaved(hoursListSaved)

            }



            
        }


    }, [IsStoreFetched])

    useEffect(() => {
        if (selectedHourFromValue !== "") {


            const start: any = selectedHourFromValue + 1;
            // const end: any = selectedHourFromValue + 1 === 24 ? 0 : 23;
            const end: any = selectedHourFromValue + 1 <= 24 ? 24 : 23;


            const range: any[] = [...Array(end - start + 1).keys()].map(x => x + start);



            setStoreHoursToList(range)
            setSelectedHourToValue("");
        }

    }, [selectedHourFromValue])


    let detailsValidationSchema = Yup.object().shape({

    });
    let detailsValidationSchema2 = Yup.object().shape({
        storePricing: Yup.string()
            .required("Required"),
        startDate: Yup.string()
            .required("Required"),
        endDate: Yup.string()
            .required("Required"),

    });

    const returnTime = (hr: any) => {
        let amOrPm = hr >= 12 && hr < 24 ? 'pm' : 'am';
        let sortedHrs = hr <= 12 ? hr : (hr % 12) || 0;

        let finalTime = `${sortedHrs === 0 ? "00" : sortedHrs} ${amOrPm}`
        return finalTime;
    }

    let originBase =  window.location.origin!=="https://vendor.mamimarkets.com" ? "https://staging.mamimarkets.com":"https://mamimarkets.com";


    
    let initialValues: any = {
        ownerFName: "",
        ownerLName: "",
        ownerTitle: "",
        city: "",
        storeAddress: `${storeInfoFetched?.address?.postalCode?`${storeInfoFetched?.address?.postalCode}`:""} ${storeInfoFetched?.address?.streetAddress?`, ${storeInfoFetched?.address?.streetAddress}`:""} ${storeInfoFetched?.address?.city?`, ${storeInfoFetched?.address?.city}`:""} ${storeInfoFetched?.address?.province?`, ${storeInfoFetched?.address?.province}`:""}` ,
        storePricingPolicy: storeInfoFetched?.pricingPolicy,
        storeReturnPolicy: storeInfoFetched?.returnPolicy,
        ownerPostCode: "",
        storeUrl: `${originBase}/stores/${storeSelectedStoreUrl(loggedAccountInfo?.url)}`,
        storeName: loggedAccountInfo?.name
    }
    let initialValues2: any = {
        storePricing: "",
        startDate: "",
        endDate: ""
    }
    const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
        return (
            <div>
                {previews}

                <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

                {files.length > 0 && submitButton}
            </div>
        )
    }
    const getUploadParams: IDropzoneProps['getUploadParams'] = () => ({ url: 'https://httpbin.org/post' })

    const handleSubmit: IDropzoneProps['onSubmit'] = (files, allFiles) => {
        
        allFiles.forEach(f => f.remove())
    }

    const updateHoursList = ({ action, day, entry, entrySaved }: any) => {

        let hoursList = [...storeHoursList];
        let hoursListSaved = [...storeHoursSaved];
        hoursList = hoursList.filter((entry: any) => entry.day !== day);
        hoursListSaved = hoursListSaved.filter((entry: any) => entry.day !== day);
        const map: any = {
            'Monday': 1,
            'Tuesday': 2,
            'Wednesday': 3,
            'Thursday': 4,
            'Friday': 5,
            'Saturday': 6,
            'Sunday': 7
        };
        
        if (action === "add") {
            hoursList.push(entry)
            hoursListSaved.push(entrySaved)
            hoursList.sort((a: any, b: any) => {
                return map[a.day] - map[b.day];
            });
            hoursListSaved.sort((a: any, b: any) => {
                return map[a.day] - map[b.day];
            });
            setStoreHoursList(hoursList)
            setStoreHoursSaved(hoursListSaved)
        }


        if (action === "delete") {
            hoursList.sort((a: any, b: any) => {
                return map[a.day] - map[b.day];
            });
            hoursListSaved.sort((a: any, b: any) => {
                return map[a.day] - map[b.day];
            });
            setStoreHoursList(hoursList)
            setStoreHoursSaved(hoursListSaved)
        }


    }

    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i: any) => {
        // this.setState({
        //     tags: this.state.tags.filter((tag, index) => index !== i),
        // });

        
        setStoreTagValues(storeTagValues.filter((tag: any, index: any) => index !== i))
        setStoreTags(allStoreTags.filter((tag: any, index: any) => index !== i))
    }

    const handleAddition = (tag: any) => {
        
        if (allStoreTags.indexOf(tag?.text) === -1) {
            setStoreTags([...allStoreTags, tag])
            setStoreTagValues([...storeTagValues, tag?.text])
        }
        // this.setState({ tags: [...tags, { id: tags.length + 1, text: tag }] });
    }

    const handleLogoUpload = (e: any) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {

            setStoreLogo({ file, image: reader.result })
            setStoreLogoNew(true)
        }

        reader.readAsDataURL(file)
    }

    return (
        <div className="inapp-content">
            {storeInfoFetched &&
            <div className="page-content cards setting-page">

                <div className="page-heading">
                    <h3>Settings</h3>
                </div>
                <div className="page-menus">
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile">
                        Store Details
                    </NavLink>

                    {/* <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/staff">
                        Staff Account
                    </NavLink> */}
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/withdrawal">
                        Withdrawal Method
                    </NavLink>

                </div>
                <div className="content-wrap">
                    {pageProps.getStoreDetailsRequest.request_status === "GET_STORE_DETAILS_SUCCESS" &&
                        <>
                            <div className="top-content">
                                {pageProps.updateStoreDetailsRequest.request_status !== "UPDATE_STORE_DETAILS_SUCCESS" &&
                                    <div className="profile-form">
                                        <Formik
                                            initialValues={initialValues}
                                            validationSchema={detailsValidationSchema}
                                            onSubmit={(values: any, actions) => {
                                                if (storeHoursSaved.length >= 1 || storeTagValues.length >= 1) {
                                                    let storeInfo = pageProps.getStoreDetailsRequest.request_data.response;
                                                    let payload = {
                                                        id: loggedAccountInfo?.id,
                                                        name: loggedAccountInfo?.name,
                                                        url: loggedAccountInfo?.url,
                                                        logoUrl: !storeLogo ? storeInfo?.logoUrl : null,
                                                        tags: storeTagValues,
                                                        availableTimes: storeHoursSaved,
                                                        hasSameDayDelivery: isSameDayDelivery,
                                                        instantPayoutEnabled:isInstantPayoutEnabled,
                                                        pricingPolicyEnabled: values.storePricingPolicy !== "" ? true : false,
                                                        returnPolicyEnabled: values.storeReturnPolicy !== "" ? true : false,
                                                        pricingPolicy: values.storePricingPolicy,
                                                        returnPolicy: values.storeReturnPolicy,
                                                    }

                                                    const logoFormData = new FormData();
                                                    if (storeLogo) {
                                                        logoFormData.append("logo", storeLogo?.file);
                                                    }


                                                    updateStoreDetailsInfo({ pageProps, payload, storeLogo: storeLogo ? logoFormData : null })
                                                }
                                            }}
                                        >
                                            {(props: FormikProps<any>) => {
                                                const {
                                                    values,
                                                    touched,
                                                    errors,
                                                    setFieldTouched,
                                                    handleChange,
                                                    setFieldValue,
                                                } = props;
                                                return (
                                                    <Form>
                                                        <div className="form-wrap">

                                                            <div className="each_info_wrap">
                                                                <div className="info_heading_wrap">
                                                                    <div className="main_heading_txt">Store information</div>
                                                                    <div className="sub_info_txt">Local information about your store</div>
                                                                    <div className="info_desc">To make changes to your store&apos;s location, please contact our support team <span>here</span></div>
                                                                </div>
                                                                <div className="info_data_section">
                                                                    <div className="each_data_wrap for_logo">
                                                                        <div className="logo-image">
                                                                            <div className="logo-icon">
                                                                                <img src={storeLogo ? storeLogo?.image : storeInfoFetched?.logoUrl} alt="" />
                                                                            </div>
                                                                            <div className="logo_label_wrap">
                                                                                <div className="label_head">Store Logo</div>
                                                                                <label htmlFor="vendor-logo">
                                                                                    <img src={ImgIco} alt="" />
                                                                                    {storeInfoFetched?.logoUrl ? "Upload new logo" : "Upload logo"}
                                                                                </label>
                                                                            </div>

                                                                            <input accept="image/*" onChange={(e: any) => handleLogoUpload(e)} type="file" name="" id="vendor-logo" />
                                                                        </div>
                                                                        {/* <div className="prompt_edit_cta">
                                                                            <button className="btn edit_prompt">
                                                                                <img src={EditIco} alt="" />
                                                                                Edit Info
                                                                            </button>
                                                                        </div> */}
                                                                    </div>
                                                                    <div className="each_data_wrap">
                                                                        <div className="data_label_txt">
                                                                            Store name
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <div className={errors.storeName && touched.storeName ? "invalid-input-wrap" : ""}>
                                                                                <Field
                                                                                    id="storeName"
                                                                                    value={values.storeName}
                                                                                    name="storeName"
                                                                                    type="text"
                                                                                    placeholder="Store name"
                                                                                    onChange={() => { }}
                                                                                    disabled
                                                                                    className={`input-wrap  ${errors.storeName && touched.storeName ?
                                                                                        "is-invalid-input "
                                                                                        : values.storeName !== "" ? "valid-input" : ""}`}
                                                                                />
                                                                            </div>
                                                                            <div className="url_txt">{values.storeUrl}</div>
                                                                            <ErrorMessage name="storeName" className="form-input-error" component="div" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="each_data_wrap">
                                                                        <div className="data_label_txt">
                                                                            Store address
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <div className={errors.storeAddress && touched.storeAddress ? "invalid-input-wrap" : ""}>
                                                                                <Field
                                                                                    id="storeAddress"
                                                                                    value={values?.storeAddress}
                                                                                    name="storeAddress"
                                                                                    type="text"
                                                                                    placeholder="Store name"
                                                                                    onChange={() => { }}
                                                                                    disabled
                                                                                    className={`input-wrap  ${errors.storeAddress && touched.storeAddress ?
                                                                                        "is-invalid-input "
                                                                                        : values.storeAddress !== "" ? "valid-input" : ""}`}
                                                                                />
                                                                            </div>
                                                                        
                                                                            <ErrorMessage name="storeAddress" className="form-input-error" component="div" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="each_data_wrap">
                                                                        <div className="data_label_txt">
                                                                            Store category
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <ReactTags
                                                                                delimiters={delimiters}
                                                                                suggestions={storeTagsSuggestions}
                                                                                tags={allStoreTags}
                                                                                handleDelete={handleDelete}
                                                                                handleAddition={handleAddition}
                                                                                classNames={{
                                                                                    tagInput: 'tagInputClass',
                                                                                    tagInputField: 'input-wrap',
                                                                                    suggestions: 'suggestionsClass tagSuggest',
                                                                                    activeSuggestion: 'activeSuggestionClass',
                                                                                }}
                                                                                placeholder="Search Categories"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="each_data_wrap">
                                                                        <div className="delivery_switch">
                                                                            <div className="prompt">
                                                                                Does <span>{storeInfoFetched?.name}</span> deliver on same day as purchase?
                                                                            </div>
                                                                            <Switch
                                                                                width={60}
                                                                                height={30}
                                                                                onHandleColor="#00A85B"
                                                                                onColor="#B2F6D6"
                                                                                offHandleColor="#9B9B9B"
                                                                                offColor="#D8D8D8"
                                                                                onChange={() => setIsSameDayDelivery(!isSameDayDelivery)} checked={isSameDayDelivery}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="each_data_wrap">
                                                                        <div className="day-month-wrap">
                                                                            <div className="day-select">
                                                                                <select name="" id="" onChange={(e) => setSelectedDayOfWeek(e.target.value)}>
                                                                                    <option value="">Day of week</option>
                                                                                    <option value="Monday">Monday</option>
                                                                                    <option value="Tuesday">Tuesday</option>
                                                                                    <option value="Wednesday">Wednesday</option>
                                                                                    <option value="Thursday">Thursday</option>
                                                                                    <option value="Friday">Friday</option>
                                                                                    <option value="Saturday">Saturday</option>
                                                                                    <option value="Sunday">Sunday</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="month-select">
                                                                                <label htmlFor="from-select">From</label>
                                                                                <div className="day-select">
                                                                                    <select name="" id="from-select" onChange={(e: any) => {
                                                                                        setSelectedHourFromValue(parseInt(e.target.value))
                                                                                        setSelectedHourFrom(e.target.options[e.target.selectedIndex].text)
                                                                                    }}>
                                                                                        <option value="" disabled={selectedHourFromValue !== ""}>Select hour</option>
                                                                                        {storeHoursFromList.map((eachHr: any, index: any) => {
                                                                                            return <option key={index} value={eachHr}>{returnTime(eachHr)}</option>
                                                                                        })}

                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="month-select">
                                                                                <label htmlFor="to-select">To</label>
                                                                                <div className="day-select">
                                                                                    <select value={selectedHourToValue} disabled={selectedHourFromValue === "" ? true : false} name="" id="to-select" onChange={(e) => {
                                                                                        setSelectedHourToValue(e.target.value)

                                                                                        setSelectedHourTo(e.target.options[e.target.selectedIndex].text)
                                                                                    }}>
                                                                                        <option value="" disabled>Select hour</option>
                                                                                        {storeHoursToList.map((eachHr: any, index: any) => {
                                                                                            return <option key={index} value={eachHr}>{returnTime(eachHr)}</option>
                                                                                        })}

                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="add-hours" onClick={() => {

                                                                                if (selectedDayOfWeek && selectedHourFrom && selectedHourTo) {
                                                                                    let entry = {
                                                                                        day: selectedDayOfWeek,
                                                                                        hours: `${selectedHourFrom} - ${selectedHourTo}`,
                                                                                        timeRanges: [{
                                                                                            from: selectedHourFromValue,
                                                                                            to: parseInt(selectedHourToValue)
                                                                                        }]
                                                                                    },
                                                                                        entrySaved = {
                                                                                            day: selectedDayOfWeek,
                                                                                            timeRanges: [{
                                                                                                from: selectedHourFromValue,
                                                                                                to: parseInt(selectedHourToValue)
                                                                                            }]
                                                                                        };
                                                                                    updateHoursList({
                                                                                        action: "add",
                                                                                        day: selectedDayOfWeek,
                                                                                        entry,
                                                                                        entrySaved
                                                                                    })
                                                                                }
                                                                            }}>Add operating Hours</div>
                                                                        </div>
                                                                        {storeHoursList.length >= 1 &&
                                                                            <div className="days-list">
                                                                                {
                                                                                    storeHoursList.map((eachEntry: any, index: any) => {

                                                                                        return (
                                                                                            <div className="each-day" key={index}>
                                                                                                <div className="day-clock">
                                                                                                    <img src={Clock} alt="" />
                                                                                                    <span>{eachEntry.day}</span>
                                                                                                </div>
                                                                                                <div className="time-choice">{eachEntry.hours}</div>
                                                                                                <div className="remove-cta" onClick={() => {
                                                                                                    updateHoursList({
                                                                                                        action: "delete",
                                                                                                        day: eachEntry.day,
                                                                                                        entry: "",
                                                                                                        entrySaved: ""
                                                                                                    })
                                                                                                }}>
                                                                                                    <img src={RemoveIcon2} alt="" />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="each_info_wrap">
                                                                <div className="info_heading_wrap">
                                                                    <div className="main_heading_txt">Store&apos;s payment withdrawal setting</div>
                                                                    <div className="sub_info_txt">If Instant Payout is enabled, <span>a fee of 1% is charged by payment processor</span> . This setting is only applicable to <span>withdrawal settings that was setup with your Bank Card</span> </div>
                                                                </div>
                                                                <div className="info_data_section">
                                                                <div className="each_data_wrap">
                                                                        <div className="delivery_switch">
                                                                            <div className="prompt">
                                                                                Do you want receive Instant Payment for <span>{storeInfoFetched?.name}</span> for all purchases?
                                                                            </div>
                                                                            <Switch
                                                                                width={60}
                                                                                height={30}
                                                                                onHandleColor="#00A85B"
                                                                                onColor="#B2F6D6"
                                                                                offHandleColor="#9B9B9B"
                                                                                offColor="#D8D8D8"
                                                                                onChange={() => setInstantPayoutEnabled(!isInstantPayoutEnabled)} checked={isInstantPayoutEnabled}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="each_info_wrap">
                                                                <div className="info_heading_wrap">
                                                                    <div className="main_heading_txt">Store&apos;s pricing policy</div>
                                                                    <div className="sub_info_txt">Enter your store&apos;s dynamic pricing policy here so that users can make more informed decisions while shopping.</div>
                                                                </div>
                                                                <div className="info_data_section">
                                                                    <div className="each_data_wrap  for_policy">
                                                                        <div className="data_label_txt">
                                                                            Pricing policy
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <div className={errors.storePricingPolicy && touched.storePricingPolicy ? "invalid-input-wrap" : ""}>
                                                                                <Field
                                                                                    id="storePricingPolicy"
                                                                                    value={values.storePricingPolicy}
                                                                                    name="storePricingPolicy"
                                                                                    as="textarea"
                                                                                    placeholder="Enter Policy"
                                                                                    onChange={handleChange}

                                                                                    className={`input-wrap  ${errors.storePricingPolicy && touched.storePricingPolicy ?
                                                                                        "is-invalid-input "
                                                                                        : values.storePricingPolicy !== "" ? "valid-input" : ""}`}
                                                                                />
                                                                            </div>

                                                                            <ErrorMessage name="storePricingPolicy" className="form-input-error" component="div" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="each_info_wrap mb-0">
                                                                <div className="info_heading_wrap">
                                                                    <div className="main_heading_txt">Store&apos;s return policy</div>
                                                                    <div className="sub_info_txt">Explain your store&apos;s return policy to customers so they can shop with confidence and peace of mind.</div>
                                                                </div>
                                                                <div className="info_data_section">
                                                                    <div className="each_data_wrap  for_policy">
                                                                        <div className="data_label_txt">
                                                                            Return policy
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <div className={errors.storeReturnPolicy && touched.storeReturnPolicy ? "invalid-input-wrap" : ""}>
                                                                                <Field
                                                                                    id="storeReturnPolicy"
                                                                                    value={values.storeReturnPolicy}
                                                                                    name="storeReturnPolicy"
                                                                                    as="textarea"
                                                                                    placeholder="Enter Policy"
                                                                                    onChange={handleChange}

                                                                                    className={`input-wrap  ${errors.storeReturnPolicy && touched.storeReturnPolicy ?
                                                                                        "is-invalid-input "
                                                                                        : values.storeReturnPolicy !== "" ? "valid-input" : ""}`}
                                                                                />
                                                                            </div>

                                                                            <ErrorMessage name="storeReturnPolicy" className="form-input-error" component="div" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="each_info_wrap">
                                                                <div className="info_heading_wrap"></div>
                                                                <div className="info_data_section">
                                                                    <div className="submit_wrapper">
                                                                        <div className="m-auto">


                                                                            {pageProps.updateStoreDetailsRequest.request_status === "UPDATE_STORE_DETAILS_FAILURE" &&
                                                                                <AlertMsg type="error" message={pageProps.updateStoreDetailsRequest?.request_data.error} />
                                                                            }
                                                                        </div>
                                                                        <div className="update-store-cta">
                                                                            <button
                                                                                disabled={pageProps.updateStoreDetailsRequest?.is_request_processing || storeHoursSaved.length < 1 || storeTagValues.length < 1}
                                                                                className={pageProps.updateStoreDetailsRequest?.is_request_processing || storeHoursSaved.length < 1 || storeTagValues.length < 1 ? "btn btn-disabled" : "btn"}
                                                                            >
                                                                                {pageProps.updateStoreDetailsRequest.is_request_processing ? "Please wait..." : "Update Store"}


                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="some-details">
                                                                <div className="logo-image">
                                                                    <div className="logo-icon">
                                                                        <img src={storeLogo ? storeLogo?.image : storeInfoFetched?.logoUrl} alt="" />
                                                                        <label htmlFor="vendor-logo">{storeInfoFetched?.logoUrl ? "update logo" : "upload logo"} </label>
                                                                        
                                                                        <input accept="image/*" onChange={(e: any) => handleLogoUpload(e)} type="file" name="" id="vendor-logo" />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className={errors.storeName && touched.storeName ? "invalid-input-wrap" : ""}>
                                                                        <Field
                                                                            id="storeName"
                                                                            value={values.storeName}
                                                                            name="storeName"
                                                                            type="text"
                                                                            placeholder="Store name"
                                                                            onChange={() => { }}
                                                                            disabled
                                                                            className={`input-wrap  ${errors.storeName && touched.storeName ?
                                                                                "is-invalid-input "
                                                                                : values.storeName !== "" ? "valid-input" : ""}`}
                                                                        />
                                                                    </div>
                                                                    <ErrorMessage name="storeName" className="form-input-error" component="div" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="input-with-divider">
                                                                        <div className="divider store-url forinapp">
                                                                            <div className="label-text">Store URL</div>
                                                                            
                                                                        </div>
                                                                        <div>
                                                                            <Field
                                                                                disabled
                                                                                id="storeUrl"
                                                                                value={values.storeUrl}
                                                                                name="storeUrl"
                                                                                type="text"
                                                                                placeholder=""
                                                                                onChange={() => { }}
                                                                                className={`input-wrap valid-input`}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <ReactTags
                                                                        delimiters={delimiters}
                                                                        suggestions={storeTagsSuggestions}
                                                                        tags={allStoreTags}
                                                                        handleDelete={handleDelete}
                                                                        handleAddition={handleAddition}
                                                                        classNames={{
                                                                            tagInput: 'tagInputClass',
                                                                            tagInputField: 'input-wrap',
                                                                            suggestions: 'suggestionsClass tagSuggest',
                                                                            activeSuggestion: 'activeSuggestionClass',
                                                                        }}
                                                                        placeholder="Search Categories"
                                                                    />
                                                                </div>
                                                            </div> */}




                                                            {/* <div className="other-wrap">
                                                                <div className="other-store-info">
                                                                    <div className="content-head">Store Info</div>
                                                                    <div className="delivery_switch">
                                                                        <div className="prompt">
                                                                            Does <span>{storeInfoFetched?.name}</span> deliver on same day as purchase?
                                                                        </div>
                                                                        <Switch
                                                                            width={60}
                                                                            height={30}
                                                                            onHandleColor="#00A85B"
                                                                            onColor="#B2F6D6"
                                                                            offHandleColor="#9B9B9B"
                                                                            offColor="#D8D8D8"
                                                                            onChange={() => setIsSameDayDelivery(!isSameDayDelivery)} checked={isSameDayDelivery}
                                                                        />
                                                                    </div>
                                                                    <div className="day-month-wrap">
                                                                        <div className="day-select">
                                                                            <select name="" id="" onChange={(e) => setSelectedDayOfWeek(e.target.value)}>
                                                                                <option value="">Day of week</option>
                                                                                <option value="Monday">Monday</option>
                                                                                <option value="Tuesday">Tuesday</option>
                                                                                <option value="Wednesday">Wednesday</option>
                                                                                <option value="Thursday">Thursday</option>
                                                                                <option value="Friday">Friday</option>
                                                                                <option value="Saturday">Saturday</option>
                                                                                <option value="Sunday">Sunday</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="month-select">
                                                                            <label htmlFor="from-select">From</label>
                                                                            <div className="day-select">
                                                                                <select name="" id="from-select" onChange={(e: any) => {
                                                                                    setSelectedHourFromValue(parseInt(e.target.value))
                                                                                    setSelectedHourFrom(e.target.options[e.target.selectedIndex].text)
                                                                                }}>
                                                                                    <option value="" disabled={selectedHourFromValue !== ""}>Select hour</option>
                                                                                    {storeHoursFromList.map((eachHr: any, index: any) => {
                                                                                        return <option key={index} value={eachHr}>{returnTime(eachHr)}</option>
                                                                                    })}

                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="month-select">
                                                                            <label htmlFor="to-select">To</label>
                                                                            <div className="day-select">
                                                                                <select value={selectedHourToValue} disabled={selectedHourFromValue === "" ? true : false} name="" id="to-select" onChange={(e) => {
                                                                                    setSelectedHourToValue(e.target.value)

                                                                                    setSelectedHourTo(e.target.options[e.target.selectedIndex].text)
                                                                                }}>
                                                                                    <option value="" disabled>Select hour</option>
                                                                                    {storeHoursToList.map((eachHr: any, index: any) => {
                                                                                        return <option key={index} value={eachHr}>{returnTime(eachHr)}</option>
                                                                                    })}

                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="add-hours" onClick={() => {

                                                                            if (selectedDayOfWeek && selectedHourFrom && selectedHourTo) {
                                                                                let entry = {
                                                                                    day: selectedDayOfWeek,
                                                                                    hours: `${selectedHourFrom} - ${selectedHourTo}`,
                                                                                    timeRanges: [{
                                                                                        from: selectedHourFromValue,
                                                                                        to: parseInt(selectedHourToValue)
                                                                                    }]
                                                                                },
                                                                                    entrySaved = {
                                                                                        day: selectedDayOfWeek,
                                                                                        timeRanges: [{
                                                                                            from: selectedHourFromValue,
                                                                                            to: parseInt(selectedHourToValue)
                                                                                        }]
                                                                                    };
                                                                                updateHoursList({
                                                                                    action: "add",
                                                                                    day: selectedDayOfWeek,
                                                                                    entry,
                                                                                    entrySaved
                                                                                })
                                                                            }
                                                                        }}>Add operating Hours</div>
                                                                    </div>
                                                                    {storeHoursList.length >= 1 &&
                                                                        <div className="days-list">
                                                                            {
                                                                                storeHoursList.map((eachEntry: any, index: any) => {

                                                                                    return (
                                                                                        <div className="each-day" key={index}>
                                                                                            <div className="day-clock">
                                                                                                <img src={Clock} alt="" />
                                                                                                <span>{eachEntry.day}</span>
                                                                                            </div>
                                                                                            <div className="time-choice">{eachEntry.hours}</div>
                                                                                            <div className="remove-cta" onClick={() => {
                                                                                                updateHoursList({
                                                                                                    action: "delete",
                                                                                                    day: eachEntry.day,
                                                                                                    entry: "",
                                                                                                    entrySaved: ""
                                                                                                })
                                                                                            }}>
                                                                                                <img src={RemoveIcon2} alt="" />
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="w-50 m-auto">


                                                                    {pageProps.updateStoreDetailsRequest.request_status === "UPDATE_STORE_DETAILS_FAILURE" &&
                                                                        <AlertMsg type="error" message={pageProps.updateStoreDetailsRequest?.request_data.error} />
                                                                    }
                                                                </div>
                                                                <div className="update-store-cta">
                                                                    <button
                                                                        disabled={pageProps.updateStoreDetailsRequest?.is_request_processing || storeHoursSaved.length < 1 || storeTagValues.length < 1}
                                                                        className={pageProps.updateStoreDetailsRequest?.is_request_processing || storeHoursSaved.length < 1 || storeTagValues.length < 1 ? "btn btn-disabled" : "btn"}
                                                                    >
                                                                        {pageProps.updateStoreDetailsRequest.is_request_processing ? "Please wait..." : "Update Store"}

                                                                       
                                                                    </button>
                                                                </div>
                                                            </div> */}
                                                            {/* <div className="other-wrap">
                                                                <div className="other-store-info">
                                                                    <div className="content-head">Store&apos;s pricing policy</div>
                                                                    <div className="policy_helptxt">
                                                                        Enter your store&apos;s dynamic pricing policy here so that users can make more informed decisions while shopping.
                                                                    </div>
                                                                    <div className="policy_box"></div>

                                                                </div>

                                                            </div> */}
                                                            {/* <div className="submit_wrapper">
                                                                <div className="w-50 m-auto">


                                                                    {pageProps.updateStoreDetailsRequest.request_status === "UPDATE_STORE_DETAILS_FAILURE" &&
                                                                        <AlertMsg type="error" message={pageProps.updateStoreDetailsRequest?.request_data.error} />
                                                                    }
                                                                </div>
                                                                <div className="update-store-cta">
                                                                    <button
                                                                        disabled={pageProps.updateStoreDetailsRequest?.is_request_processing || storeHoursSaved.length < 1 || storeTagValues.length < 1}
                                                                        className={pageProps.updateStoreDetailsRequest?.is_request_processing || storeHoursSaved.length < 1 || storeTagValues.length < 1 ? "btn btn-disabled" : "btn"}
                                                                    >
                                                                        {pageProps.updateStoreDetailsRequest.is_request_processing ? "Please wait..." : "Update Store"}


                                                                    </button>
                                                                </div>
                                                            </div> */}

                                                        </div>

                                                    </Form>
                                                )
                                            }}

                                        </Formik>
                                    </div>
                                }
                                {pageProps.updateStoreDetailsRequest.request_status === "UPDATE_STORE_DETAILS_SUCCESS" &&
                                    <div className="success-message-wrap w-50 m-auto">
                                        <div className="success-animation">
                                            <svg className="success_icon_tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                                        </div>
                                        <div className="success-message-txt">{loggedAccountInfo?.name} was successfully updated</div>
                                        <div className="product-cta">
                                            <button
                                                onClick={() => {
                                                    updateStoreDetailsInfo({
                                                        pageProps,
                                                        payload: "CLEAR"
                                                    })
                                                    history.replace("/app")
                                                }}
                                                className="btn">
                                                Proceed to Dashboard?
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>

                        </>
                    }
                    {pageProps.getStoreDetailsRequest.request_status === "GET_STORE_DETAILS_PENDING" &&
                        <div className="other-wrap">
                            <LoadingItem />
                        </div>
                    }
                </div>
            </div>
            }
        </div>
    )
}

const StoreProfile = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Store Profile" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    getStoreDetails: storeActions.GetStoreDetails,
    updateStoreDetails: storeActions.UpdateStoreDetails,
};

const mapStateToProps = (state: any) => ({
    // return {
    updateStoreDetailsRequest: state.allProductReducers.updateStoreDetailsReducer,
    getStoreDetailsRequest: state.allProductReducers.getStoreDetailsReducer,

    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(StoreProfile);