import { useEffect, useState, useRef, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from "react-router-dom"
import readXlsxFile from 'read-excel-file'
import { history } from "../../../shared/_helpers/history";
import * as XLSX from 'xlsx';
import { useWindowSize } from "../../../hooks/useWindowSize";
import "react-datepicker/dist/react-datepicker.css";
import { TableComponent } from "../../../shared/components/table"
import { InAppTemplate } from "../../../shared/templates/inapp";

import LeftArrow from "../../../assets/icons/left-arrow.png"
import UploadIco from "../../../assets/upload-ico.png"
import HelpIco from "../../../assets/help.png"
import "../index.scss"
import { AlertMsg } from "../../../shared/components/alert-msg"

import { storeActions } from "../../../redux/actions/in-app"

const axios = require('axios');

const uploadAllProducts = async ({ pageProps, payload }: any) => {

    await pageProps.uploadBulkProductAction(payload);
}







const MainHeader = () => {
    return (
        <div className="product-info-header">
            <div className="left-nav" onClick={() => history.replace("/app/my-products")}> <img src={LeftArrow} alt="" /> </div>
            <div className="other-item-wrap">
                <div className="heading-text">Add multiple products</div>
                <div className="heading-msg">Upload a CSV file containing details of the products. For additional support, have a look at our Help Center</div>
            </div>
        </div>
    )
}
const ReportTable = ({ reportData }: any) => {

    let headers: any[] = reportData[0];
    // let headers: any[] = reportData.shift();
    // let listItems: any[] = reportData;
    let listItems: any[] = reportData.slice(1);


    return (
        <>
            <thead>
                <tr>
                    {
                        headers.map((eachItem: any, index: any) => {

                            return (
                                <th key={index}>
                                    {(index == 0 || index == headers.length - 1) &&
                                        <span>{eachItem}</span>
                                    }
                                    {(index !== 0 && index !== headers.length - 1) &&
                                        <>{eachItem}</>
                                    }


                                </th>
                            )
                        })
                    }

                </tr>
            </thead>
            <tbody>
                {
                    listItems.map((eachItem: any, index: any) => {

                        return (
                            <tr key={index}>
                                {
                                    eachItem.map((eachValue: any, valueIndex: any) => {
                                        // let cellValue = eachValue 
                                        //                    ? eachValue.toString():"";
                                        let cellValue;
                                        if (eachValue == false || eachValue) {
                                            cellValue = eachValue.toString()
                                        } else {
                                            cellValue = ""
                                        }

                                        return (
                                            <td key={valueIndex}>
                                                <div className="each_data">
                                                    {cellValue}
                                                </div>

                                            </td>
                                        )
                                    })
                                }
                                <td>
                                </td>
                            </tr>
                        )
                    })
                }

            </tbody>
        </>
    )
}
const ProductsReview = ({ allProducts, productsFile, pageProps }: any) => {
    // let headers: any[] = allProducts.shift();
    // let listItems: any[] = allProducts;
    let request = pageProps.uploadStoreBulkProductsRequest
    const screenResolution: any = useWindowSize();
    const handleUploadBulk = () => {
        let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
        loggedAccountInfo = JSON.parse(loggedAccountInfo);
        const productUploadData = new FormData();
        productUploadData.append("StoreId", loggedAccountInfo.selectedStore.id);
        productUploadData.append("ExcelFile", productsFile);

        uploadAllProducts({ pageProps, payload: productUploadData })
    }
    return (
        <div className="list-contanier">
            <>
                {request.request_status !== "UPLOAD_MULTIPLE_SUCCESS" &&
                    <div className="review_heading">
                        <div className="heading_txt">Review {(screenResolution?.width >= 768) && 'products'} </div>
                        <div className="upload_cta">
                            <button
                                disabled={request?.is_request_processing}
                                onClick={handleUploadBulk}
                                className="btn upload_now">{request?.is_request_processing ? "Uploading..." : "Add all products to store"}</button>
                        </div>
                    </div>
                }
                {request.request_status === "UPLOAD_MULTIPLE_FAILURE" &&
                    <AlertMsg type="error" message={request?.request_data.error} />
                }
                {request.request_status !== "UPLOAD_MULTIPLE_SUCCESS" &&
                    <div className="list-content-wrap  products-list ">
                        <div className="all-items">
                            <div className="all-items-list">
                                <TableComponent
                                    childComponent={<ReportTable reportData={allProducts} />}
                                />
                            </div>
                        </div>
                    </div>
                }
            </>
            {request.request_status === "UPLOAD_MULTIPLE_SUCCESS" &&
                <div className="success-message-wrap w-50 m-auto">
                    <div className="success-animation">
                        <svg className="success_icon_tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                    </div>
                    <div className="success-message-txt">Bulk Products was successfully uploaded</div>
                    <div className="product-cta">
                        <button
                            onClick={() => {
                                uploadAllProducts({
                                    pageProps,
                                    payload: "CLEAR"
                                })
                                history.replace("/app/my-products")
                            }}
                            className="btn">
                            See All Products?
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

const UploadProducts = ({ setProductsList, setProductsFile }: any) => {
    const [fileUrl, setFileUrl] = useState<any>("");
    const [importingUrl, setImportingUrl] = useState<boolean>(false);
    const handleFileUpload = (e: any) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];


        setProductsFile(file)
        readXlsxFile(e.target.files[0]).then((rows: any) => {

            setProductsList(rows)
            // `rows` is an array of rows
            // each row being an array of cells.
        })
        reader.onloadend = () => {
            //   setStoreLogo({ file, image: reader.result });
        };

        reader.readAsDataURL(file);
    };

    const validURL = (str: string) => {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    const handleFetchFromUrl = async (fileurl: string) => {
        if (fileurl.length >= 5 && validURL(fileurl)) {
            try {
                setImportingUrl(true)
                const url = fileurl;
                const response = await axios.get(url, { responseType: 'arraybuffer' });

                const data = new Uint8Array(response.data);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                let uploadFilter = jsonData.filter((eachRow: any) => eachRow.length == 12)
                setProductsList(uploadFilter)
                setImportingUrl(false)

            } catch (error) {
                console.error('Error fetching or parsing Excel file:', error);
            }
        }
    };
    return (
        <div className="upload_wrap">
            <div className="upload_heading">Upload CSV file</div>
            <div className="upload-container">
                <div className="upload_control">
                    <label htmlFor="upload-products">
                        <div className="control_wrap">
                            <div className="icon_wrap">
                                <img src={UploadIco} alt="" />
                            </div>
                            <div className="drag_txt">Choose file to upload</div>
                            <div className="file-size">Max file size: 10MB</div>
                        </div>
                    </label>
                    <input
                        id="upload-products"
                        type="file"
                        onChange={handleFileUpload}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                </div>
                <div className="help_file">Please ensure you  <a download={true} href="/BulkProductUpload.xlsx">download this template</a> to  use for your product catalog before you upload. Please note that if this template is not used your products will not be uploaded</div>
                <div className="import_from_url">
                    <div className="form-group">
                        <label htmlFor="">Or upload from URL</label>
                        <input type="text"
                            placeholder="Add file URL"
                            onChange={(e: any) => setFileUrl(e.target.value.trim())}
                            className="input-wrap" />
                        {/* <div className="import_prompt">Upload</div> */}
                    </div>
                </div>
                <div className="cta_wrap">
                    <div className="help_link">
                        <img src={HelpIco} alt="" />
                        <Link to={`/app/help-center`}>

                            <span>Help Center</span>
                        </Link>
                    </div>
                    <div className="ctas_list">
                        <button className="btn cancel">Cancel</button>
                        <button
                            disabled={importingUrl || validURL(fileUrl) == false}
                            className="btn submit" onClick={() => {
                                handleFetchFromUrl(fileUrl)
                            }}>{importingUrl ? "Importing..." : "Import products"}</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

const InAppContent = ({ pageProps }: any) => {
    const screenResolution: any = useWindowSize();
    const [allProducts, setProductsList] = useState<any[]>([])
    const [productsFile, setProductsFile] = useState<any>()

    useEffect(() => {
        uploadAllProducts({
            pageProps,
            payload: "CLEAR"
        })
    }, [])

    return (
        <div className="inapp-content">
            <div className="page-content form-page">
                {(screenResolution?.width <= 767) &&
                    <div className="mobile_back_nav">
                        <div className="left-nav" onClick={() => history.replace("/app/my-products")}> <img src={LeftArrow} alt="" /> </div>
                        <div className="back_nav_txt">
                            Import Products
                        </div>
                    </div>
                }
                {(screenResolution?.width <= 767) &&
                    <div className="other-item-wrap for_import">
                        <div className="heading-msg">Upload a CSV file containing details of the products. For additional support, have a look at our Help Center</div>
                    </div>
                }
                <div className="product-form for-upload">
                    {allProducts.length == 0 &&
                        <UploadProducts
                            setProductsList={setProductsList}
                            setProductsFile={setProductsFile}
                        />
                    }

                    {allProducts.length >= 1 &&
                        <ProductsReview
                            allProducts={allProducts}
                            productsFile={productsFile}
                            pageProps={pageProps}
                        />
                    }

                </div>
            </div>
        </div>
    )
}
const AddMultipleProducts = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Add multiple products" showMainPageHeading={true} mainPageHeading={<MainHeader />} childComponent={<InAppContent pageProps={pageProps} />} />
    )
}

const mapDispatchToProps = {

    uploadBulkProductAction: storeActions.UploadBulkProduct

};

const mapStateToProps = (state: any) => ({

    uploadStoreBulkProductsRequest: state.allProductReducers.uploadStoreBulkProductsReducer,

    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(AddMultipleProducts);