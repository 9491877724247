import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';
import * as Yup from 'yup';

const InAppContent = () => {
    const [storeAddressValue, setStoreAddressValue] = useState<any>(null);
    let detailsValidationSchema = Yup.object().shape({
        ownerFName: Yup.string()
            .required("Required"),
        ownerLName: Yup.string()
            .required("Required"),
        city: Yup.string()
            .required("Required"),
        storeAddress: Yup.string()
            .required("Required")

    });
    let initialValues: any = {
        ownerFName: "",
        ownerLName: "",
        ownerTitle: "",
        city: "",
        storeAddress: "",
        ownerPostCode: "",
    }
    return (
        <div className="inapp-content">
            <div className="page-content cards setting-page">

                <div className="page-heading">
                    <h3>Settings</h3>
                </div>
                <div className="page-menus">
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile">
                        Personal Details
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/store">
                        Store Details
                    </NavLink>
                    {/* <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/staff">
                        Staff Account
                    </NavLink> */}
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/withdrawal">
                        Withdrawal Method
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/delete">
                        Delete Account
                    </NavLink>
                </div>
                <div className="content-wrap">
                    <div className="profile-form main-profile">
                        <div className="some-details">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={detailsValidationSchema}
                                onSubmit={(values: any, actions) => {

                                }}
                            >
                                {(props: FormikProps<any>) => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        setFieldTouched,
                                        handleChange,
                                        setFieldValue,
                                    } = props;
                                    return (
                                        <Form>
                                            <div className="form-wrap">
                                                <div className="">

                                                    <div className="form-group">
                                                        {/* <div>

                                                        <div className={`input-with-divider ${errors.ownerFName && touched.ownerFName ?
                                                            "is-invalid-input withwrap "
                                                            : values.ownerFName !== "" ? "valid-input" : ""}`}>
                                                            <div className="divider">
                                                                <div className={errors.ownerTitle && touched.ownerTitle ? "invalid-input-wrap" : ""}>
                                                                    <Field
                                                                        value={values.ownerTitle}
                                                                        name="ownerTitle"
                                                                        as="select"
                                                                        onChange={handleChange}
                                                                        placeholder=""
                                                                        className={`form-control inapp-input ${errors.ownerTitle && touched.ownerTitle ?
                                                                            "is-invalid-input "
                                                                            : !errors.ownerTitle && values.ownerTitle !== "" ? "valid-input" : ""}`}
                                                                    >
                                                                        <option value="">Title</option>
                                                                        <option value="Mrs">Mrs</option>
                                                                        <option value="Ms.">Ms.</option>
                                                                        <option value="Mr">Mr</option>
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Field
                                                                    id="ownerFName"
                                                                    value={values.ownerFName}
                                                                    name="ownerFName"
                                                                    type="text"
                                                                    placeholder="First name"
                                                                    onChange={handleChange}
                                                                    className={`input-wrap ${errors.ownerFName && touched.ownerFName ?
                                                                        "is-invalid-input "
                                                                        : values.ownerFName !== "" ? "valid-input" : ""}`}
                                                                />

                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="ownerFName" className="form-input-error" component="div" />
                                                        <ErrorMessage name="ownerTitle" className="form-input-error" component="div" />
                                                    </div> */}
                                                        {/* <div> */}
                                                        <Field
                                                            id="ownerLName"
                                                            value={values.ownerLName}
                                                            name="ownerLName"
                                                            type="text"
                                                            placeholder="Full Name"
                                                            onChange={handleChange}
                                                            className={`input-wrap ${errors.ownerLName && touched.ownerLName ?
                                                                "is-invalid-input "
                                                                : values.ownerLName !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="ownerLName" className="form-input-error" component="div" />
                                                        {/* </div> */}
                                                    </div>
                                                    {/* <div className="form-group">
                                                    <Field
                                                        id="city"
                                                        value={values.city}
                                                        name="city"
                                                        type="text"
                                                        placeholder="City"
                                                        onChange={handleChange}
                                                        className={`input-wrap ${errors.city && touched.city ?
                                                            "is-invalid-input "
                                                            : values.city !== "" ? "valid-input" : ""}`}
                                                    />
                                                    <ErrorMessage name="city" className="form-input-error" component="div" />
                                                </div> */}
                                                    <div className="form-group store_address">
                                                        {/* <Field
                                                        id="storeAddress"
                                                        value={values.storeAddress}
                                                        name="storeAddress"
                                                        type="text"
                                                        placeholder="Address"
                                                        onChange={handleChange}
                                                        className={`input-wrap ${errors.storeAddress && touched.storeAddress ?
                                                            "is-invalid-input "
                                                            : values.storeAddress !== "" ? "valid-input" : ""}`}
                                                    /> */}
                                                        <GooglePlacesAutocomplete
                                                            selectProps={{
                                                                value: storeAddressValue,
                                                                onChange: setStoreAddressValue,
                                                                // onChange: setValue,
                                                                placeholder: "Store Address",
                                                                classNamePrefix: "react-select",
                                                                isClearable: true,
                                                                // onBlur:()=>{
                                                                //   console.log("onBlur")
                                                                // },
                                                                // onInputChange:()=>{
                                                                //   console.log("onInputChange", value)
                                                                // },
                                                                noOptionsMessage: () => {
                                                                    return null
                                                                    // if (value) {
                                                                    //   // console.log("lalalal", value)
                                                                    //   return null
                                                                    //   // return "Address not found";
                                                                    // } else {
                                                                    //   // console.log("dddddd")
                                                                    //   return null
                                                                    // }
                                                                }
                                                                // components:{{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                                            }}
                                                            apiKey="AIzaSyBgo1zf5mTGQpvXu4hPjQ6c5Cg2Ppo4Klc"
                                                            autocompletionRequest={{
                                                                componentRestrictions: {
                                                                    country: ['ca'],
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage name="storeAddress" className="form-input-error" component="div" />
                                                    </div>
                                                    {/* <div className="form-group">
                                                    <div className="input-with-divider">
                                                        <div className="divider normal">
                                                            <div className="label-text">Postal Code</div>
                                                            <div className="other-txt">94105</div>
                                                        </div>
                                                        <div>
                                                            <Field
                                                                id="ownerPostCode"
                                                                value={values.ownerPostCode}
                                                                name="ownerPostCode"
                                                                type="text"
                                                                placeholder=""
                                                                // onChange={handleChange}
                                                                className={`input-wrap valid-input`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="email-phone-ctas">
                                                    <button type="button" className="each-cta">Change Email</button>
                                                    <button type="button" className="each-cta">Change Phone</button>
                                                </div> */}



                                                </div>

                                            </div>
                                            <div className="submit-cta">
                                                <button className="btn">Edit Details</button>
                                            </div>
                                        </Form>
                                    )
                                }}

                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const PersonalProfile = () => {
    return (
        <InAppTemplate pageHeadingTitle="Personal Profile" childComponent={<InAppContent />} />
    )
}


const mapDispatchToProps = {

};

const mapStateToProps = (state: any) => ({
    // return {


    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(PersonalProfile);